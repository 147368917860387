import React from 'react';
import './../componentStyles/footer.css';
import logo from "../images/MC_logo-1.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={logo} alt="Morse & Code Logo" />
        </div>
        <div className="footer-info">
          <div className="footer-section">
            <h3>Morse & Code s.r.o.</h3>
            <p className='footer-selection-text'>Accelerate your innovation and transformation with a fully integrated suite of capabilities that puts digital at the heart of everything.</p>
          </div>
          <div className="footer-section">
            <h3>Contacts</h3>
            <p>Spálená 480/1<br />602 00 Brno-Trnitá</p>
            <p><a href="mailto:info@morsecode.cz">info@morsecode.cz</a></p>
          </div>
          <div className="footer-section">
            <h3>Information</h3>
            <p>IČ: 21426937</p>
            <p>DIČ: CZ21426937</p>
            <p>Krajský soud v Brně<br />oddíl C, vložka 138867</p>
            <p>ID datové schránky: 2tm7mws</p>
          </div>
          <div className="footer-section">
            <h3>Follow us</h3>
            <div className="social-icons">
              <a href="https://www.linkedin.com/company/103496080/" className="linkedin-icon" aria-label="LinkedIn"></a>
              <a href="https://www.linkedin.com/company/103496080/" className="twitter-icon" aria-label="Twitter"></a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© Morse & Code s.r.o. | Designed by Morse Design</p>
      </div>
    </footer>
  );
};

export default Footer;